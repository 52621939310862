<template>
  <div id="app">
    <Tabs></Tabs>
    <!-- 内容区 -->
    <div id="content">
      <!-- <img src="/pc/img/gif/音量音乐gif动图.gif" alt="勤学教育"> -->
      <!-- 版心 -->
      <div class="type_page">
        <!-- 页面分隔符 -->
        <ul class="break_box">
          <li
            @click="
              $router.push({ name: 'Personal', params: { is_pageid: 8 } })
            "
          >
            我的课程
          </li>
          <li>
            <img src="../../../assets/img/right.png" alt="勤学教育" />
          </li>
          <li>
            <el-dropdown trigger="click" placement="bottom" @command="oneclick">
              <span class="el-dropdown-link">
                {{ one.show.name }}
              </span>
              <el-dropdown-menu class="downmenu" slot="dropdown">
                <el-dropdown-item
                  :command="item"
                  v-for="item in one.list"
                  :key="'one' + item.name"
                  >{{ item.name }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <!-- <a href="/pc/personal.html?page_id=8">个人中心</a> -->
          </li>
          <li>
            <img src="../../../assets/img/right.png" alt="勤学教育" />
          </li>
          <li>
            <el-dropdown trigger="click" placement="bottom" @command="twoclick">
              <span class="el-dropdown-link">
                {{ two.show.chapter_category_name }}
              </span>
              <el-dropdown-menu class="downmenu" slot="dropdown">
                <el-dropdown-item
                  :command="item"
                  v-for="item in two.list"
                  :key="'two2' + item.chapter_category_id"
                  >{{ item.chapter_category_name }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </li>
          <!-- <li><img src="/pc/img/right.png" alt="勤学教育" /></li> -->

          <li>
            <img src="../../../assets/img/right.png" alt="勤学教育" />
          </li>
          <li style="cursor: pointer">
            <el-dropdown
              trigger="click"
              placement="bottom"
              @command="threeclick"
            >
              <span style="color: #3e7eff" class="el-dropdown-link">
                {{ three.show.name }}
              </span>
              <el-dropdown-menu class="downmenu" slot="dropdown">
                <el-dropdown-item
                  :command="item"
                  v-for="item in three.list"
                  :key="'three' + item.chapter_id"
                  >{{ item.name }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </li>
        </ul>
        <!-- 专业 -->
        <div id="nav_bar_box">
          <img
            v-if="tap_nav.left > 0"
            class="hoverimg"
            src="../../../assets/img/leftclasss.png"
            alt=""
            @click="tapnavclick(true)"
          />
          <img
            v-else
            class="hoverimg"
            src="../../../assets/img/leftclass.png"
            alt=""
          />
          <ul>
            <li ref="tapnav">
              <div
                :class="[
                  two.show.chapter_category_id == item.chapter_category_id
                    ? 'nav_name_active isnav'
                    : 'isnav',
                ]"
                v-for="item in two.list"
                :key="item.chapter_category_id"
                @click="twoclick(item)"
              >
                <el-dropdown @command="threeintwo">
                  <span
                    :style="
                      two.show.chapter_category_id == item.chapter_category_id
                        ? 'color:#fff'
                        : ''
                    "
                    class="el-dropdown-link"
                  >
                    {{ item.chapter_category_name }}
                  </span>
                  <el-dropdown-menu class="downmenu" slot="dropdown">
                    <el-dropdown-item
                      :command="el"
                      v-for="el in item.list"
                      :key="'twolist' + el.chapter_id"
                      >{{ el.name }}</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </li>
          </ul>
          <img
            v-if="tap_nav.isdom < this.two.list.length && tap_nav.right"
            class="hoverimg"
            src="../../../assets/img/rightclass.png"
            alt=""
            @click="tapnavclick(false)"
          />
          <img
            v-else
            class="hoverimg"
            src="../../../assets/img/rightclasss.png"
            alt=""
          />
        </div>

        <!-- 视频 -->
        <div class="my_video_box">
          <div class="t">
            <div class="l" :style="!videoshow ? 'width:870px;' : 'width:100%;'">
              <div id="player"></div>
            </div>
            <div class="r" v-show="!videoshow">
              <div
                class="rt"
                @mouseover="$refs.chapter.style.height = '100%'"
                @mouseout="$refs.chapter.style.height = '0'"
              >
                <span>
                  <img src="../../../assets/img/myclasscheck.png" alt="" />
                  切换章节
                </span>
                <p>{{ three.show.name }}</p>
                <div class="chapter" ref="chapter">
                  <ul>
                    <li
                      @click="threeclick(item)"
                      v-for="item in three.list"
                      :key="item.chapter_id"
                      :class="
                        item.chapter_id == three.show.chapter_id ? 'act' : ''
                      "
                    >
                      <span></span>{{ item.name }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="rtab">
                <span :class="isvdeo ? 'acttab' : ''" @click="isvdeo = true"
                  >视频列表</span
                >
                <span :class="!isvdeo ? 'acttab' : ''" @click="isvdeo = false"
                  >讲义下载</span
                >
              </div>
              <div v-show="isvdeo" class="rlist videolist">
                <img
                  v-show="videos.list.length <= 0"
                  src="../../../assets/img/zanwushipin@2x.png"
                  alt=""
                  style="display: block; width: 266px; margin: 61px auto 0"
                />
                <div
                  :class="
                    item.video_id == videos.show.video_id
                      ? 'card videoact'
                      : 'card'
                  "
                  v-for="item in videos.list"
                  :key="item.video_id"
                  @click="changeVideo(item)"
                >
                  <div class="cardt">
                    <h2>
                      {{ item.video_title }}
                    </h2>
                  </div>
                  <div class="cardb">
                    <span>总时长：{{ alltime(item.video_duration) }}</span>
                    <span v-show="item.is_finish == 0"
                      >已学:{{
                        parseInt((item.duration / item.video_duration) * 100)
                      }}%</span
                    >
                    <span v-show="item.is_finish == 1">已学完</span>
                  </div>
                </div>
              </div>
              <div
                v-show="!isvdeo"
                class="rlist handout infinite-list"
                infinite-scroll-immediate="false"
                v-infinite-scroll="handoutload"
                :infinite-scroll-disabled="handouts.over"
              >
                <img
                  v-show="handouts.list.length <= 0"
                  src="../../../assets/img/zanwujiangyi@2x.png"
                  alt=""
                  style="display: block; width: 266px; margin: 61px auto 0"
                />
                <div
                  class="card"
                  v-for="(item, index) in handouts.list"
                  :key="item.course_book_id"
                >
                  <div class="cardt">
                    {{ handouts.total - index }}.
                    <div class="tit">
                      <img :src="imgsrc(item.title)" alt="" />
                      <h2 class="text2">
                        {{ item.title }}
                      </h2>
                    </div>
                  </div>
                  <div class="cardb">
                    <div class="cardbl">
                      <p>{{ item.updatetime }}</p>
                      <p>文件大小: {{ item.format_size }}</p>
                    </div>
                    <a :href="item.url" class="cardbr">查看</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="b">{{ videos.show.video_title }}</div>
          <div class="old" v-if="oldshow">
            <div class="desc">
              <div class="desctit">
                <img src="../../../assets/img/myclasstime.png" alt="" />
                上次观看记录
              </div>
              <h2 class="descbt">
                {{ watch_record.video_title }}
              </h2>
              <div class="buton">
                <span @click="oldclick1">重新选择</span>
                <span @click="oldclick2">继续学习</span>
              </div>
              <div class="oldshow">
                <el-checkbox v-model="checked">今天不再提醒</el-checkbox>
              </div>
            </div>
          </div>
        </div>
        <!-- 版心结尾 -->
      </div>
    </div>
    <!-- <Foot></Foot> -->
  </div>
</template>

<script>
import infiniteScroll from "vue-infinite-scroll";
import api from "@/api/api";
export default {
  directives: {
    infiniteScroll,
  },
  data() {
    return {
      checked: false,
      oldshow: false,
      vodPlayerJs: "https://player.polyv.net/script/player.js",
      videoshow: false,
      watch_record: [],
      one: {
        list: [],
        show: "",
      },
      two: {
        list: [],
        show: "",
      },
      three: {
        list: [],
        show: "",
      },
      isvdeo: true,
      videos: {
        list: [],
        show: "",
      },
      handouts: {
        list: [],
        show: "",
        total: 0,
        page: 0,
        over: false,
      },
      tap_nav: {
        isdom: 0,
        left: 0,
        right: false,
      },
    };
  },
  methods: {
    handoutload() {
      if (!this.handouts.over && !this.isvdeo) {
        this.handouts.page = this.handouts.page + 1;
        api
          .course_chapter_book({
            chapter_category: this.one.show.id,
            chapter_id: this.three.show.chapter_id,
            page: this.handouts.page,
          })
          .then((res) => {
            if (res.data.code == 1) {
              this.handouts.list = [
                ...this.handouts.list,
                ...res.data.data.list,
              ];
              if (res.data.data.list.length < res.data.data.page_size) {
                this.handouts.over = true;
              }
            } else {
              // this.handouts.list = [];
              this.$message.error(res.data.msg);
            }
            // console.log(res)
          });
      } else {
        return;
      }
    },
    //tapnav click
    tapnavclick(e) {
      // console.log(this.tap_nav.isdom);
      // console.log(this.tap_nav);
      let dom = document.getElementsByClassName("isnav");
      if (e) {
        this.tap_nav.isdom = this.tap_nav.isdom - 1;
        let px = this.tap_nav.left - this.two.list[this.tap_nav.isdom].width;
        this.tap_nav.left = px;
        this.$refs.tapnav.style.left = "-" + px + "px";
      } else {
        let px = this.tap_nav.left + this.two.list[this.tap_nav.isdom].width;
        this.tap_nav.left = px;
        this.$refs.tapnav.style.left = "-" + px + "px";
        this.tap_nav.isdom = this.tap_nav.isdom + 1;
      }
    },

    // 获取章节列表
    getTitle() {
      var datas = {
        chapter_category: this.course_id,
        two_coursecate_id: this.two_coursecate_id,
      };
      api.chapter_list_v1(datas).then((res) => {
        if (parseInt(res.data.code) == 401) {
          this.$message.error({
            message: "请先登录!",
            duration: "2000",
          });
          this.$router.push({
            name: "Login",
          });
        }
        if ((res.data.code = 1)) {
          this.major_list =
            this.major_list.length == 0 ? res.data.data : this.major_list;
          this.chapter_list = res.data.data[0].list;
          this.major_title = res.data.data[0].chapter_category_name;
          this.is_selectNav = res.data.data[0].chapter_category_id;
          this.getChapterId(0, false);
          console.log();
        }
      });
    },
    //
    oneclick(e) {
      // console.log(e);
      this.one.show = e;
      this.gettwo(e.id);
    },
    threeintwo(e) {
      console.log(e);
      this.two.list.forEach((a) => {
        a.list.forEach((b) => {
          if (b.chapter_id == e.chapter_id) {
            this.two.show = a;
            this.three.list = a.list;
            this.threeclick(e);
            return;
          } else {
          }
        });
      });
    },
    twoclick(e) {
      // console.log(e);
      this.two.show = e;
      this.three.list = e.list;
      this.threeclick(e.list[0]);
    },
    threeclick(e) {
      this.three.show = e;
      this.getvideo(e.chapter_id);
      this.$refs.chapter.style.height = 0;
    },
    //
    //获取课程列表
    getone(one_id, two_id, three_id) {
      api.my_course_cate().then((res) => {
        if (parseInt(res.data.code) == 401) {
          this.$message.error({
            message: "请先登录!",
            duration: "2000",
          });
          this.$router.push({
            name: "Login",
          });
        }
        // console.log(res);
        this.one.list = res.data.data;
        if (two_id && three_id) {
          res.data.data.forEach((e) => {
            if (e.id == one_id) {
              this.one.show = e;
            }
          });
          this.gettwo(one_id, two_id, three_id);
        } else {
          this.one.show = res.data.data[0];
          this.gettwo(res.data.data[0].id);
        }
      });
    },
    //获取章节列表
    gettwo(one_id, two_id, three_id) {
      api.chapter_list_v1({ two_coursecate_id: one_id }).then((res) => {
        // console.log(res);
        this.two.list = res.data.data;

        this.$nextTick(() => {
          let a = document.getElementsByClassName("isnav");
          let b = 0;
          let isdom = "";
          // console.log(a);
          for (let i = 0; i < a.length; i++) {
            this.two.list[i].width = a[i].clientWidth;
            b = b + a[i].clientWidth;
            if (b > 960 && isdom == "") {
              isdom = i;
              this.two.list[i].width = b - 960;
            }
            // console.log(i);
          }
          // console.log(b);
          this.tap_nav.right = b > 960 ? true : false;
          this.$refs.tapnav.style.left = 0;
          this.tap_nav.isdom = isdom;
          // console.log(this.two.list);
        });

        if (two_id && three_id) {
          res.data.data.forEach((e) => {
            if (e.chapter_category_id == two_id) {
              this.two.show = e;
              this.three.list = e.list;
              e.list.forEach((c) => {
                if (c.chapter_id == three_id) {
                  this.three.show = c;
                  return;
                }
              });
            }
          });
          this.getvideo(three_id);
        } else {
          this.two.show = res.data.data[0];
          this.three.list = res.data.data[0].list;
          this.three.show = res.data.data[0].list[0];
          this.getvideo(res.data.data[0].list[0].chapter_id);
        }
        // res.data.data.forEach(e => {
        //   if(two_id)
        // });
      });
    },
    //计算时间
    alltime(time) {
      let createtime = "";
      if (time >= 60) {
        let s = time % 60;
        let m = parseInt(time / 60);
        if (m >= 60) {
          let h = parseInt(m / 60);
          m = m % 60;
          if (h >= 24) {
            let d = parseInt(h / 24);
            createtime = d + "天" + h + "时" + m + "分" + s + "秒";
          } else {
            // console.log(m);
            createtime = h + "时" + m + "分" + s + "秒";
          }
        } else {
          createtime = m + "分" + s + "秒";
        }
      } else {
        createtime = time + "秒";
      }
      return createtime;
    },

    formatTime() {
      let date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours();
      const minute = date.getMinutes();
      const second = date.getSeconds();

      // return (
      //   [year, month, day].join("/") + " " + [hour, minute, second].join(":")
      // );
      return [year, month, day]
        .map((e) => {
          return e > 10 ? e : "0" + e;
        })
        .join("");
    },
    //继续上次观看
    oldclick1() {
      this.oldshow = false;
      if (this.checked) {
        let time = this.formatTime();
        localStorage.setItem("oldshowtime", time);
      }
    },
    oldclick2() {
      //  this.videos.show = this.watch_record[0];
      let oldone = this.one.list.find(
        (e) => e.id == this.watch_record.two_coursecate_id
      );
      let oldtwo = this.two.list.find(
        (e) => e.chapter_category_id == this.watch_record.three_chaptercate_id
      );
      this.one.show = oldone;
      this.two.show = oldtwo;
      this.three.list = oldtwo.list;
      this.three.show = oldtwo.list.find(
        (e) => e.chapter_id == this.watch_record.chapter_id
      );
      this.getvideo(this.three.show.chapter_id, this.watch_record.video_id);
      // console.log(oldone);
      // console.log(oldtwo);

      this.oldshow = false;
      if (this.checked) {
        let time = this.formatTime();
        localStorage.setItem("oldshowtime", time);
      }
    },
    //获取视频列表
    getvideo(three_id, videoid) {
      api
        .chapter_detail({
          chapter_category: this.one.show.id,
          chapter_id: three_id,
        })
        .then((res) => {
          console.log(res);
          if (res.data.code == 401) {
            this.$message.error({
              message: "请先登录!",
              duration: "2000",
            });
            this.$router.push({
              name: "Login",
            });
          }
          //上传观看记录
          if (res.data.code == 1) {
            if (this.videos.show != "") {
              this.getVideoStatus(this.videos.show);
            } else {
              let time = this.formatTime();
              let oldtime = localStorage.getItem("oldshowtime");
              if (
                (oldtime && oldtime == time) ||
                res.data.data.watch_record.length == 0
              ) {
                this.oldshow = false;
              } else {
                this.oldshow = true;
                let w = res.data.data.watch_record.video_title.split("/");
                res.data.data.watch_record.video_title = w[w.length - 1];
                this.watch_record = res.data.data.watch_record;
              }
            }
            // console.log(res);
            res.data.data.video_list.forEach((e) => {
              let a = e.video_title.split("/");
              e.video_title = a[a.length - 1];
            });

            this.videos.list = res.data.data.video_list;
            if (videoid) {
              this.videos.show = res.data.data.video_list.find(
                (e) => e.video_id == videoid
              );
            } else {
              this.videos.show = res.data.data.video_list[0];
            }
            if (this.player) {
              this.changeVideo(this.videos.show);
            } else {
              this.loadPlayerScript(this.loadPlayer);
            }
          } else {
            if (this.videos.show != "") {
              this.getVideoStatus(this.videos.show);
              history.pushState(
                "",
                "",
                `/myClass/8/${this.two.show.chapter_category_id}/${this.one.show.id}?threeid=${three_id}`
              );
            }
            this.videos.list = [];
            // this.oldshow = false
            this.$message.error(res.data.msg);
          }
        });
      //获取讲义列表
      api
        .course_chapter_book({
          chapter_category: this.one.show.id,
          chapter_id: three_id,
        })
        .then((res) => {
          this.handouts.page = 1;
          this.handouts.total = 0;
          if (res.data.code == 1) {
            this.handouts.list = res.data.data.list;
            this.handouts.total = res.data.data.total_item_count;
          } else {
            this.handouts.list = [];
            // this.$message.error(res.data.msg);
          }
          // console.log(res)
        });
    },
    // 保利威视频事件
    loadPlayerScript(callback) {
      // console.log(window.polyvPlayer);
      if (!window.polyvPlayer) {
        const myScript = document.createElement("script");
        myScript.setAttribute("src", this.vodPlayerJs);
        myScript.onload = callback;
        document.body.appendChild(myScript);
        // console.log(123);
      } else {
        // console.log(456);
        callback();
      }
    },
    loadPlayer() {
      // setTimeout(() => {
      const polyvPlayer = window.polyvPlayer;
      // console.log(polyvPlayer);
      let options = {
        wrap: "#player",
        height: 556,
        logo: {
          logo_width: "auto", //Logo宽 默认 'auto'
          logo_height: "auto", //Logo高 默认 'auto'
          logo_url: require("../../../assets/img/video_logo.png"), // Logo图片URL
          logo_pos: 2, //Logo显示位置，取值范围为：{0,1,2,3,4}，分别代表：隐藏、左上、右上、左下、右下
          logo_link: "", // 点击Logo的跳转链接 (选填)
          logo_alpha: 100, //Logo透明度，取值范围：(0,100)
          logoOffset: "95,5", //Logo偏移量，单位：百分比。坐标原点为播放器左上角，只在Logo位置为左上、右上时生效
          logo_resize: true, //默认值为false，为true时会根据播放器窗口大小动态调整Logo的尺寸，若设置了logo宽高则该参数不生效
        },
      };
      if (this.videos.show.polyv_vid != "") {
        options.vid = this.videos.show.polyv_vid;
        options.playsafe = this.getVidToken(this.videos.show.polyv_vid);
      } else {
        options.url = this.videos.show.video_url;
      }

      this.player = polyvPlayer(options);
      let that = this;
      this.player.on("s2j_onPlayerInitOver", () => {
        let isdom = document.querySelector(
          "#player .pv-controls-left .videonext"
        );
        if (!isdom) {
          let dom = document.querySelector(
            "#player .pv-controls-left .pv-time-wrap"
          );
          // document.querySelector("#player .pv-controls-right").style.right =
          //   "50px";
          let im = document.createElement("div");
          im.className = "videonext";

          let rightdom = document.createElement("div");
          rightdom.className = "pv-component-wrap pv-fullscreen-wrap";
          rightdom.innerHTML = `
        <button type="button" class="pv-fullscreen pv-iconfont pv-icon-fullscreen kp"></button>
        <div class="pv-fullscreen-tip pv-control-wrap-style">宽屏</div>
        `;
          document
            .querySelector("#player .pv-controls-right")
            .appendChild(rightdom);

          document
            .querySelector("#player .pv-controls-left")
            .insertBefore(im, dom);
          //下一个
          im.addEventListener("click", function () {
            // console.log(that.videos.show);
            let next = that.videos.list.findIndex(
              (e) => e.video_id == that.videos.show.video_id
            );
            // console.log(next);
            if (next == that.videos.list.length - 1) {
              that.$message("本章节已学完，请切换章节~");
            } else {
              that.changeVideo(that.videos.list[next + 1]);
            }
          });
          //宽屏
          rightdom.addEventListener("click", function () {
            that.videoshow = !that.videoshow;
            if (!that.videoshow) {
              rightdom.innerHTML = `
        <button type="button" class="pv-fullscreen pv-iconfont pv-icon-fullscreen kp"></button>
        <div class="pv-fullscreen-tip pv-control-wrap-style">宽屏</div>
        `;
            } else {
              rightdom.innerHTML = `
        <button type="button" class="pv-fullscreen pv-iconfont pv-icon-fullscreen esckp"></button>
        <div class="pv-fullscreen-tip pv-control-wrap-style">退出宽屏</div>
        `;
            }
          });
        }

        // this.loading = false
        //订阅播放器初始化完毕事件
        // this.player.changeVid(option);
        // player.j2s_seekVideo(60); //视频seek到第60秒
      });
    },
    // 点击切换视频
    changeVideo(e) {
      let a = JSON.stringify(this.videos.show);
      this.getVideoStatus(JSON.parse(a));
      this.videos.show = e;
      const option = {};
      if (e.polyv_vid != "" && e.polyv_vid) {
        option.vid = e.polyv_vid;
        option.playsafe = this.getVidToken(e.polyv_vid);
      } else {
        option.url = e.video_url;
      }
      this.player.changeVid(option);
    },
    //讲义图片匹配
    imgsrc(e) {
      let a = e.split(".");
      let b = a[a.length - 1];
      let c = [
        "txt",
        "zip",
        "xlsx",
        "xls",
        "tiff",
        "tar",
        "rar",
        "ppt",
        "png",
        "md",
        "jpg",
        "jpeg",
        "gz",
        "docx",
        "doc",
        "csv",
        "bz2",
        "bmp",
      ].indexOf(b);
      if (c > -1) {
        return require(`../../../assets/img/${b}.png`);
      } else {
        return require(`../../../assets/img/tongyong.png`);
      }
      // console.log(a[a.length - 1])
    },

    //保利威视频
    async getVidToken(vid) {
      // var getToken = async function() {
      // 调接口
      var data = {
        // token: localStorage.getItem("token"),
        vid: vid,
      };
      var token = "";
      await api
        .play_safe(data)
        .then((res) => {
          if (parseInt(res.data.code) == 1) {
            token = res.data.data.token;
          }
        })
        .catch((error) => {
          // return "";
        });
      return token;
    },

    // 上传视频观看记录
    getVideoStatus(v) {
      // console.log(v);
      if (!v.is_finish || v.is_finish == 0 || v.duration < v.video_duration) {
        let time = this.player.j2s_getDuration();
        let showtime = this.player.j2s_getCurrentTime(); // 观看时长
        if (showtime > 10) {
          let datas = {
            courses_id: this.course_id, //课程id
            chapters_id: this.chapter_id, // 章节id
            video_id: v.video_id, //video id
            // video_duration: time, // 视频时长
            duration: parseInt(showtime), // 观看时长
            two_coursecate_id: this.one.show.id,
          };
          api.video_recordv1(datas).then((res) => {
            this.videos.list.map((e) => {
              if (e.video_id == v.video_id) {
                if (res.data.data.is_finish == 1) {
                  e.is_finish = 1;
                  e = Object.assign({ is_finish: 1 }, e);
                }
                e.duration = parseInt(showtime);
                this.$forceUpdate();
              }
            });
            // console.log(this.video_list)
            // else {
            //   this.video_list.map((e) => {
            //     if (e.video_id == v.video_id) {
            //       e = Object.assign({ is_finish: 1 }, e);
            //     }
            //   });
            //   // console.log(this.video_list);
            // }
          });
        }
      }
    },
  },
  created() {},

  mounted() {
    let oneid = this.$route.params.coursecate_id;
    let twoid = this.$route.params.course_id;
    let threeid = this.$route.query.threeid;
    this.getone(oneid, twoid, threeid);
    // setTimeout(() => {
    //   let a = document.getElementsByClassName("isnav");
    //   this.tap_nav.dom = a.length - 1;
    //   let b = 0;
    //   for (let i = 0; i < a.length; i++) {
    //     b = b + a[i].clientWidth;
    //     if (b > 960) {
    //       // console.log(i);
    //       // console.log(a.length)
    //       // this.tap_nav.isdom = i == a.length - 1 ? i - 1 : i;
    //       // this.tap_nav.rdom.num = i;
    //       // this.tap_nav.rdom.px = b - 960;

    //       // console.log(b - 960);
    //       return;
    //     } else {
    //       this.tap_nav.isdom = i;
    //       this.tap_nav.rdom.num = i;
    //     }
    //   }
    // }, 500);
  },
  destroyed() {
    this.getVideoStatus(this.videos.show);
    if (this.player) {
      this.player.destroy();
    }
  },
};
</script>

<style lang="scss" scoped>
.downmenu {
  max-height: 300px;
  overflow-y: auto;
  padding: 0 15px;
  border-radius: 8px;
  box-shadow: 0px 0px 16px 0px rgba(0, 11, 19, 0.14);
}
::v-deep .el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: transparent;
  color: #3f7dff;
}
::v-deep .el-dropdown-menu__item {
  border-bottom: 1px solid #f5f5f5;
  padding: 15px;
  width: 212px;
  line-height: 22px;
  font-size: 15px;
  color: #666666;
  font-weight: 500;
}
::v-deep #player {
  .esckp:hover::before {
    background: url("../../../assets/img/eschover.png") no-repeat center;
    background-size: 100%;
  }
  .esckp::before {
    content: "";
    width: 20px;
    height: 20px;
    display: block;
    background: url("../../../assets/img/kuan.png") no-repeat center;
    background-size: 100%;
  }
  .kp:hover::before {
    background: url("../../../assets/img/kuanzh.png") no-repeat center;
    background-size: 100%;
  }
  .kp::before {
    content: "";
    width: 20px;
    height: 20px;
    display: block;
    background: url("../../../assets/img/kuanz.png") no-repeat center;
    background-size: 100%;
  }
  .videonext:hover {
    background: url("../../../assets/img/videonexthover.png") no-repeat center;
    background-size: 100%;
  }
  .videonext {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin: 14px 10px 0 0;
    background: url("../../../assets/img/videonext.png") no-repeat center;
    background-size: 100%;
  }
}
::v-deep #player div {
  white-space: normal;
}
.hoverimg {
  width: 22px;
  margin: 0 47px;
  cursor: pointer;
}
/* 内容区 */

#content {
  width: 100%;
  /* height: 100vh; */
  /* height: 3000px; */
  background: #f5f5f5;
  overflow: hidden;
}

.horizontal-collapse-transition {
  transition: 0s width ease-in-out, 0s padding-left ease-in-out,
    0s padding-right ease-in-out;
}

/* 版心 */

#content .type_page {
  width: 1200px;
  margin: 38px auto 0;
  /* height: 100px; */
  /* background: pink; */
}

/* 页面分隔符 */

#content .type_page .break_box {
  display: flex;
  align-items: center;
}

.type_page .break_box img {
  width: 12px;
  height: 14px;
  margin: 0 16px;
}

#content .type_page .break_box li,
.break_box li a {
  cursor: pointer;
  height: 16px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #989898;
  line-height: 16px;
}

/* 专业 */

.major_box {
  width: 100%;
  height: 78px;
  background: #fff;
  margin: 20px 0;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}

.major_box li {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 400;
  margin-right: 30px;
  padding: 7px 20px;
  cursor: pointer;
}

.major_box li:nth-child(1) {
  margin-left: 35px;
}

.major_active {
  padding: 7px 18px;
  color: #3e7eff;
  background: #e9f2ff;
  border-radius: 18px;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  -ms-border-radius: 18px;
  -o-border-radius: 18px;
}

/* 视频 */
::v-deep .el-checkbox__label {
  font-size: 18px;
  font-weight: 500;
  color: #000000 !important;
}
::v-deep .el-checkbox__inner {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid #999999;
  border-radius: 2px;
}
::v-deep .el-checkbox__inner::after {
  width: 6px;
  height: 10px;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #3e7eff;
  border-color: #3e7eff;
}
::v-deep .el-checkbox__inner {
  background-color: transparent;
}
.my_video_box {
  // margin-bottom: 132px;
  position: relative;
  margin-top: 30px;
  background: #fff;
  .old {
    z-index: 99;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    backdrop-filter: blur(7px);
    .desc {
      width: 870px;
      height: 556px;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .oldshow {
        display: flex;
        margin-top: 15px;
        align-items: center;
        justify-content: center;
      }
      .descbt {
        font-size: 26px;
        font-weight: bold;
        color: #3f7dff;
        display: flex;
        max-width: 80%;
        text-align: center;
        justify-content: center;
        margin: 24px auto 19px;
        line-height: 28px;
      }
      .buton {
        display: flex;
        align-items: center;
        justify-content: center;
        span:first-child {
          background: transparent;
          color: #3f7dff;
          margin-right: 57px;
        }
        span {
          width: 132px;
          cursor: pointer;
          height: 42px;
          border: 2px solid #3f7dff;
          border-radius: 40px;
          font-size: 22px;
          font-weight: bold;
          background: #3f7dff;
          color: #fff;
          text-align: center;
          line-height: 42px;
        }
      }
      .desctit {
        justify-content: center;
        display: flex;
        align-items: center;
        font-size: 24px;
        font-weight: 500;
        img {
          width: 33px;
          // height: 24px;
        }
      }
    }
  }
  .t {
    display: flex;
    .l {
      // width: 100%;
      width: 870px;
      height: 556px;
    }
    .r {
      width: 330px;
      position: relative;
      .chapter {
        width: 100%;
        height: 0;
        position: absolute;
        top: 0;
        left: -330px;
        transition: all 0.3s;
        background: rgba(32, 32, 32, 0.78);
        backdrop-filter: blur(7px);
        overflow-y: scroll;
        ul {
          .act {
            color: #3f7dff !important;
            span {
              background: #3f7dff !important;
            }
          }
          li:hover {
            color: #fff;
            span {
              background: #fff;
            }
          }
          li {
            display: flex;
            cursor: pointer;
            font-size: 16px;
            font-weight: 500;
            color: #ffffff;
            align-items: center;
            padding: 15px 25px;
            border-bottom: 1px solid rgba(238, 239, 240, 0.06);
            span {
              min-width: 8px;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: #eaedf7;
              margin-right: 14px;
            }
          }
        }
      }
      .rt:hover {
        .chapter {
          height: 100%;
          z-index: 999;
        }
      }
      .rt {
        padding: 15px;
        border-bottom: 1px solid #ededed;
        span {
          cursor: pointer;
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          color: #3f7dff;
          img {
            width: 16px;
          }
        }
        p {
          margin-top: 11px;
          font-size: 22px;
          height: 52px;
          overflow: hidden;
          font-weight: 500;
          color: #000b2f;
          line-height: 26px;
        }
      }
      .rtab {
        display: flex;
        align-items: center;
        .acttab {
          color: #3f7dff;
          font-weight: bold;
          position: relative;
        }
        .acttab::after {
          content: "";
          display: block;
          position: absolute;
          bottom: 5px;
          left: 50%;
          transform: translateX(-50%);
          width: 54px;
          height: 4px;
          background: #3f7dff;
          border-radius: 4px;
        }
        span {
          cursor: pointer;
          width: 50%;
          text-align: center;
          padding: 16px 0;
          font-size: 18px;
          font-weight: 500;
          color: #010101;
        }
      }
      .rlist {
        overflow-y: scroll;
        height: 385px;
        background: #f7f7f7;
      }
      .handout {
        .card:hover {
          background: rgba(62, 126, 255, 0.1);
          .cardb {
            .cardbl {
              color: #3f7dff;
            }
            .cardbr {
              border: 1px solid #3f7dff;
              color: #fff;
              background: #3f7dff;
            }
          }
          .cardt {
            color: #3f7dff;
          }
        }
        .card {
          padding: 16px 20px 10px 10px;
          cursor: pointer;
          transition: all 0.3s;
          .cardb {
            margin-top: 9px;
            padding-left: 28px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .cardbl {
              font-size: 12px;
              font-weight: 500;
              line-height: 1;
              color: #575757;
              p:first-child {
                margin-bottom: 7px;
              }
            }
            .cardbr {
              width: 51px;
              height: 25px;
              border: 1px solid #999999;
              border-radius: 4px;
              font-size: 14px;
              font-weight: 500;
              color: #212121;
              line-height: 26px;
              text-align: center;
            }
          }
          .cardt {
            display: flex;
            align-items: center;
            // justify-content: space-between;
            font-size: 18px;
            font-weight: 500;
            color: #000000;
            white-space: nowrap;
            .tit {
              margin-left: 8px;
              display: flex;
              align-items: center;
              img {
                width: 34px;
                height: 34px;
                margin-right: 3px;
              }
              h2 {
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;
              }
            }
          }
        }
      }
      .videolist {
        .videoact {
          background: rgba(62, 126, 255, 0.1);
          .cardt h2 {
            background: url("../../../assets/img/video_gif.gif") no-repeat top
              left !important;
            background-size: 25px 25px !important;
            color: #3f7dff !important;
          }
          .cardb span {
            color: #3f7dff !important;
          }
        }
        .card:hover {
          background: rgba(62, 126, 255, 0.1);
          .cardt h2 {
            background: url("../../../assets/img/bofang_hover.png") no-repeat
              top left;
            // background-size: 20px 20px;
            color: #3f7dff !important;
          }
          .cardb span {
            color: #3f7dff !important;
          }
        }
        .card {
          padding: 19px 23px 16px 20px;
          cursor: pointer;
          transition: all 0.3s;
          .cardb {
            padding-left: 27px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            span {
              font-size: 14px;
              font-weight: 500;
              color: #575757;
            }
          }
          .cardt {
            display: flex;
            margin-bottom: 16px;
            h2 {
              padding-left: 27px;
              background: url("../../../assets/img/bofang_normal.png") no-repeat
                top left;
              // background-size: 20px 20px;
              // max-width: 246px;
              font-size: 16px;
              font-weight: 500;
              color: #000000;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
  .b {
    padding: 36px 20px 30px;
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #000b2f;
  }
}

.downmenu::-webkit-scrollbar,
.chapter::-webkit-scrollbar,
.rlist::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

/*正常情况下滑块的样式*/
.downmenu::-webkit-scrollbar-thumb,
.chapter::-webkit-scrollbar-thumb,
.rlist::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  -webkit-box-shadow: inset1px1px0rgba(0, 0, 0, 0.1);
}

/*鼠标悬浮在该类指向的控件上时滑块的样式*/
.downmenu:hover::-webkit-scrollbar-thumb,
.chapter:hover::-webkit-scrollbar-thumb,
.rlist:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  -webkit-box-shadow: inset1px1px0rgba(0, 0, 0, 0.1);
}

/*鼠标悬浮在滑块上时滑块的样式*/
.downmenu::-webkit-scrollbar-thumb:hover,
.chapter::-webkit-scrollbar-thumb:hover,
.rlist::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: inset1px1px0rgba(0, 0, 0, 0.1);
}

/*正常时候的主干部分*/
.downmenu::-webkit-scrollbar-track,
.chapter::-webkit-scrollbar-track,
.rlist::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0);
  background-color: #eeeeee;
}

/*鼠标悬浮在滚动条上的主干部分*/
.downmenu::-webkit-scrollbar-track:hover,
.chapter::-webkit-scrollbar-track:hover,
.rlist::-webkit-scrollbar-track:hover {
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.4);
  background-color: rgba(0, 0, 0, 0.01);
}
/* 导航 */

#nav_bar_box {
  width: 1200px;
  background: #fff;
  // height: 70px;

  margin: 20px auto 0;
  border-radius: 6px;
  padding: 14px 0;
  // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
}

#nav_bar_box ul {
  width: 952px;
  margin: auto;
  overflow: hidden;
  display: flex;
  // justify-content: space-around;
  align-items: center;
}

#nav_bar_box ul li {
  width: auto;
  display: flex;
  transition: all 0.3s;
  display: -webkit-inline-box;
  position: relative;
  left: 0;
  // justify-content: space-around;
  // flex-wrap: wrap;
  // width: 100%;
  // margin-left: 20px;
  align-items: center;
}

#nav_bar_box ul li div {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  // margin-top: 15px;
  // margin-right: 65px;
  text-align: center;
  padding: 5px 20px;
  cursor: pointer;
}

.nav_name_active {
  color: #fff;
  padding: 7px 20px;
  border-radius: 6px;
  /* background: #3e7eff26; */
  background: #3e7eff;
  border-radius: 20px;
}
.block0 {
  background: #000;
}
</style>
